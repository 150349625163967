export const apiConstant={
    setApiStart:'setApiStart',
    setApiSuccess:'setApiSuccess',
    setApiFailure:'setApiFailure'
}

export const toastConstant={
    setToast:'setToast',
    infoToast:'infoToast',
    successToast:'successToast',
    warnToast:'warnToast',
    errorToast:'errorToast',
    defaultToast:'defaultToast',
    position: process.env.REACT_APP_TOAST_POSITION,
    theme:process.env.REACT_APP_TOAST_THEME
}

export const userConstant={
    setAdminUser:'setAdminUser',
    logoutAdminUser:'logoutAdminUser',
}
export const HRuserConstant={
    setHRUser:'setHRUser',
    logoutHRUser:'logoutHRUser',
}

