export const apiAction = (type, data) => ({
    type: type,
    assignableData: data
});
export const toastAction = (type,message) => ({
    type: type,
    message:message
});
export const toastActionAlert = (type) => ({
    type: type
});
export const userAction = (type,userDetails) => ({
    type:type,
    userDetails:userDetails
});